import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./slider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import CustomVideo from "../cards/customVideo";
import videoThumbnail1 from "../../images/home/thumbnail-video-home-chris.webp";
import videoThumbnail2 from "../../images/home/thumbnail-video-home-kristen.webp";
import videoThumbnail3 from "../../images/home/thumbnail-video-home-bridgette.webp";
import videoThumbnail4 from "../../images/home/thumbnail-video-home-lindsey.webp";
import videoThumbnail5 from "../../images/home/thumbnail-video-home-celina.webp";
import { triggerCustomGTM } from "../../utils/helper";
import usePageLoaded from "../../hooks/use-page-loaded";
const LazyCustomVideo = React.lazy(() => import("../cards/customVideo"));

const arrImg = [
    {
        thumbnail: videoThumbnail1,
        videoId: 1006947240,
        videoIdParam: "002f3e156f",
        videoAlt:
            "Video thumbnail of Chris, a real person with narcolepsy with cataplexy taking WAKIX",
        customTagTitle: "Chris video",
        videoName: "WAKIX Patient Stories - Chris",
    },
    {
        thumbnail: videoThumbnail2,
        videoId: 1006951865,
        videoIdParam: "10e9ffbdfe",
        videoAlt:
            "Video thumbnail of Kristen, a real person with narcolepsy taking WAKIX",
        customTagTitle: "Kristen video",
        videoName: "WAKIX Patient Stories - Kristen",
    },
    {
        thumbnail: videoThumbnail3,
        videoId: 1006950097,
        videoIdParam: "4b88a75636",
        videoAlt:
            "Video thumbnail of Bridgette, a real person with narcolepsy with cataplexy taking WAKIX",
        customTagTitle: "Bridgette video",
        videoName: "WAKIX Patient Stories - Bridgette",
    },
    {
        thumbnail: videoThumbnail4,
        videoId: 1006953550,
        videoIdParam: "b515377550",
        videoAlt:
            "Video thumbnail of Lindsey, a real person with narcolepsy with cataplexy taking WAKIX",
        customTagTitle: "Lindsey video",
        videoName: "WAKIX Patient Stories - Lindsey",
    },
    {
        thumbnail: videoThumbnail5,
        videoId: 1006603588,
        videoIdParam: "023aa4bf0c",
        videoAlt:
            "Video thumbnail of Celina, a real person with narcolepsy taking WAKIX",
        customTagTitle: "Celina video",
        videoName: "WAKIX Patient Stories - Celina",
    },
];

function Home() {
    const [active, setActive] = useState(0);
    const [play, setPlay] = useState(false);
    const iframeTitle = `video-${active}`;
    const { pageLoaded } = usePageLoaded();

    const handlePlay = () => {
        setPlay(true);
        sendGTM(active);
    };

    // Updated sendGTM function to use the videoName from the data
    const sendGTM = (video) => {
        triggerCustomGTM({
            event: "gtm.click",
            customTag: arrImg[video].customTagTitle,
            videoAction: "Play",
            videoName: arrImg[video].videoName,
        });
        //  console.log(`video ${video} is playing - ${arrImg[video].videoName}`);
    };

    const settings = {
        customPaging: function (i) {
            return (
                <a
                    href="#video"
                    aria-label={`Thumbnail for video ${arrImg[i].videoName}`}
                >
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <LazyCustomVideo
                            videoId={arrImg[i].videoId}
                            videoThumbnail={arrImg[i].thumbnail}
                            videoIdParam={arrImg[i].videoIdParam}
                            dontAllowPlay={true}
                            altText={arrImg[i].videoAlt}
                            //onClick={sendGTM(arrImg[i].videoIdParam)}
                            // customTagText={arrImg[i].customTagTitle}
                        />
                    </React.Suspense>
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (currSlid, nextSlide) => {
            setActive(nextSlide);
            setPlay(false);
        },
    };

    return (
        <div>
            {pageLoaded && (
                <div
                    style={{ display: play ? "" : "none" }}
                    className="card-video bg-cover main-video"
                >
                    <iframe
                        allowfullscreen=""
                        data-ready="true"
                        allow="autoplay"
                        width="100%"
                        height="100%"
                        data-vimeo-tracked="true"
                        dnt="false"
                        title={iframeTitle}
                        src={
                            play
                                ? `https://player.vimeo.com/video/${arrImg[active].videoId}?h=${arrImg[active].videoIdParam}&autoplay=1`
                                : `https://player.vimeo.com/video/${arrImg[active].videoId}?h=${arrImg[active].videoIdParam}`
                        }
                        frameBorder="0"
                    ></iframe>
                </div>
            )}
            <Slider {...settings}>
                {arrImg.map((item) => (
                    <div className={`card-video__slider ${play ? "hide" : ""}`}>
                        <React.Suspense fallback={<div>Loading...</div>}>
                            <LazyCustomVideo
                                onPlay={handlePlay}
                                videoId={item.videoId}
                                videoIdParam={item.videoIdParam}
                                videoThumbnail={item.thumbnail}
                                insideSlider={true}
                                altText={item.videoAlt}
                                customTagText={item.customTagTitle}
                                videoName={item.videoName}
                            />
                        </React.Suspense>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Home;
