import React, { useEffect, useState } from 'react'

function usePageLoaded() {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    // Function to handle user interaction
    const handleUserInteraction = () => {
      setPageLoaded(true);
      console.log('page loaded')
      // Remove the event listeners after the first interaction
      window.removeEventListener('scroll', handleUserInteraction);
      window.removeEventListener('click', handleUserInteraction);
    };

    // Set up the event listeners for user interactions
    window.addEventListener('scroll', handleUserInteraction);
    window.addEventListener('click', handleUserInteraction);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleUserInteraction);
      window.removeEventListener('click', handleUserInteraction);
    };
  }, []);
  return { pageLoaded }
}

export default usePageLoaded